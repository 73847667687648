// appConfiguration.prod.js
const config = {
    pmsBaseUrl: 'https://pms.sadhguru.org/services/pms',
    loginServerBaseUrl: 'https://ishalogin.sadhguru.org',
    supportNewTicketUrl: 'https://ishaprofile.ishafoundation.org/support/tickets/new',
    supportContactUsUrl: 'http://ishaprofile.ishafoundation.org/',
    firebaseConfig: {
        "apiKey": "AIzaSyCvkFdpUrWyvdv1tyWXw3fvl1eblo_WgxA",
        "authDomain": "ishalogin.sadhguru.org",
        "databaseURL": "https://isha-bf5e1.firebaseio.com",
        "projectId": "isha-bf5e1",
        "storageBucket": "isha-bf5e1.appspot.com",
        "messagingSenderId": "536005066803",
        "appId": "1:536005066803:web:3e270597595f97766b6346",
        "measurementId": "G-RWNVWY048N"
    }
};

export default config;
